<template>
  <div class="profile-content-wrapper">
    <div class="content-block links-block">
      <div class="content-block__title">Статистика</div>
      <div class="content-block__content">
        <div class="content-block__string mb26 align-center">
          <div class="s1">
            <span>Blizzard Battle.net</span>
            <span>Свяжите ваши учётные записи Blizzard и SGL</span>
          </div>
          <div class="s2">
            <input-checkbox></input-checkbox>
          </div>
        </div>
        <div class="content-block__string mb26 align-center">
          <div class="s1">
            <span>Steam</span>
            <span>Предоставлять метаданные игры</span>
          </div>
          <div class="s2">
            <input-checkbox></input-checkbox>
          </div>
        </div>
        <div class="content-block__string mb26 align-center">
          <div class="s1">
            <span>Playstation ID</span>
            <span>Предоставлять метаданные игры</span>
          </div>
          <div class="s2">
            <input-checkbox></input-checkbox>
          </div>
        </div>
        <div class="content-block__string mb26 align-center">
          <div class="s1">
            <span>Live ID</span>
            <span>Предоставлять метаданные игры</span>
          </div>
          <div class="s2">
            <input-checkbox></input-checkbox>
          </div>
        </div>
        <div class="content-block__string mb26 align-center">
          <div class="s1">
            <span>Origin ID</span>
            <span>Предоставлять метаданные игры</span>
          </div>
          <div class="s2">
            <input-checkbox></input-checkbox>
          </div>
        </div>
        <div class="content-block__string mb26 align-center">
          <div class="s1">
            <span>EpicGames</span>
            <span>Предоставлять метаданные игры</span>
          </div>
          <div class="s2">
            <input-checkbox></input-checkbox>
          </div>
        </div>
      </div>
    </div>
    <div class="content-block links-block">
      <div class="content-block__title">Трансляции</div>
      <div class="content-block__content">
        <div class="content-block__string mb26 align-center">
          <div class="s1">
            <span>Youtube</span>
            <span>Экспортировать видеозаписи</span>
          </div>
          <div class="s2">
            <input-checkbox></input-checkbox>
          </div>
        </div>
        <div class="content-block__string mb26 align-center">
          <div class="s1">
            <span>Twitch</span>
            <span>Экспортировать видеозаписи</span>
          </div>
          <div class="s2">
            <input-checkbox></input-checkbox>
          </div>
        </div>
        <div class="content-block__string mb26 align-center">
          <div class="s1">
            <span>Discord</span>
            <span v-html="'Коммуникация с организатором и&nbsp;участниками'"/>
          </div>
          <div class="s2">
            <input-checkbox></input-checkbox>
          </div>
        </div>
      </div>
    </div>
    <div class="content-block links-block">
      <div class="content-block__title">Авторизация</div>
      <div class="content-block__content">
        <div class="content-block__string mb26 align-center">
          <div class="s1">
            <span>Facebook</span>
            <span>Авторизации через сервис</span>
          </div>
          <div class="s2">
            <input-checkbox></input-checkbox>
          </div>
        </div>
        <div class="content-block__string mb26 align-center">
          <div class="s1">
            <span>Instagram</span>
            <span>Авторизации через сервис</span>
          </div>
          <div class="s2">
            <input-checkbox></input-checkbox>
          </div>
        </div>
        <div class="content-block__string mb26 align-center">
          <div class="s1">
            <span>Google</span>
            <span>Авторизации через сервис</span>
          </div>
          <div class="s2">
            <input-checkbox></input-checkbox>
          </div>
        </div>
        <div class="content-block__string mb26 align-center">
          <div class="s1">
            <span>Vkontakte</span>
            <span>Авторизации через сервис</span>
          </div>
          <div class="s2">
            <input-checkbox></input-checkbox>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputCheckbox from "@/components/UIElements/InputCheckbox";

export default {
  name: "profileLinks",
  components: {InputCheckbox}
}
</script>

<style scoped lang="scss">

</style>